/**
 * Represents the type of event data.
 */
export enum EventDataType {
  LOGOUT = 'logout',
  SEARCH = 'search',
  CHANGE_ADDRESS = 'change-address',
  CART_ADD_ADDRESS = 'cart-add-address',
  CHANGE_LOCALE = 'change-locale',
  CLEAR_FILTERS = 'clear-filters',
  SELECT_DISH = 'select-dish',
  SELECT_BUNDLE = 'select-bundle',
  OPEN_RESTAURANT_SEARCH_DIALOG = 'open-restaurant-search-dialog',
  OPEN_ASSISTANT = 'open-assistant',
  OPEN_MOBILE_CART_PAGE = 'open-mobile-cart-page',
}
